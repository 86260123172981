.blog-buttons {
        width: 185px;
        margin-top: 35px;
        margin-bottom: 35px;
        margin-left: 80%;
        @media screen and (max-width: 950px) {
            margin-left: 70%; }
        @media screen and (max-width: 640px) {
            margin-left: 60%; }
        @media screen and (max-width: 480px) {
            margin-left: 45%; }
        @media screen and (max-width: 320px) {
            margin-left: 40%; }
        ul {
            display: flex;
            justify-content: space-between;
            a {
                width: 37px;
                height: 37px;
                border-radius: 50%;
                &:hover {
                    background-color: #F1DFF4; }
                li {
                    color: #000000;
                    .prev {
                        border: 1px solid;
                        width: 37px;
                        height: 37px;
                        border-radius: 50%;
                        position: relative;
                        .prev-arrow {
                            position: absolute;
                            width: 15px;
                            height: 15px;
                            border-left: 1px solid;
                            border-bottom: 1px solid;
                            transform: rotate(45deg);
                            top: 29%;
                            left: 35%; } }
                    .page-i {
                        width: 37px;
                        height: 37px;
                        border: 1px solid;
                        border-radius: 50%;

                        .page-number {
                            width: 15px;
                            height: 15px;
                            margin-left: auto;
                            margin-right: auto;
                            margin-top: 15%;
                            text-align: center;
                            font-family: Arial, futuralight;
                            font-style: normal;
                            font-weight: normal;
                            font-size: 19px;
                            line-height: 24px; }
                        &:hover {
                            .page-number {
                                text-decoration: underline; } } }

                    .next {
                        border: 1px solid;
                        width: 37px;
                        height: 37px;
                        border-radius: 50%;
                        position: relative;
                        .next-arrow {
                            position: absolute;
                            width: 15px;
                            height: 15px;
                            border-left: 1px solid;
                            border-bottom: 1px solid;
                            transform: rotate(225deg);
                            top: 29%;
                            right: 40%; } } } } } }
