@import 'adaptiv/slider';
.slider-blog {
    margin-left: auto;
    margin-right: auto;
    width: 930px;
    display: flex;
    flex-wrap: wrap;
    @media screen and (max-width: 930px) {
        width: 380px;
        overflow-x: hidden;
        overflow-y: hidden; }
    @media screen and (max-width: 480px) {
        width: 100%; }
    .slider {
        width: 522px;
        height: 350px;
        position: relative;
        @media screen and (max-width: 930px) {
            width: 100%;
            height: auto; }
        @media screen and (max-width: 480px) {
            width: 100%; }
        .single-item {
            width: 100%;
            @media screen and (max-width: 930px) {
                height: auto; }
            .newSlider__item {
                position: relative;
                width: 100%;
                height: 383px;
                background-color: #ffffff;
                @media screen and (max-width: 930px) {
                    height: auto; }
                @media screen and (max-width: 480px) {
                    height: 340px; }
                img {
                    max-width: 100%;
                    height: 100%;
                    margin-left: auto;
                    margin-right: auto;
                    @media screen and (max-width: 930px) {
                        height: 283px; }
                    @media screen and (max-width: 480px) {
                        max-width: 100%;
                        margin-left: auto;
                        margin-right: auto; } }
                .slider-item-link {
                    position: absolute;
                    bottom: 13px;
                    left: 13px;
                    height: 97px;
                    width: 223px;
                    background-color: #ffffff;
                    padding-top: 20px;
                    padding-left: 20px;
                    box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.25);
                    @media screen and (max-width: 480px) {
                        bottom: 0;
                        width: 100%;
                        background: #F1DFF4;
                        left: inherit;
                        height: 80px;
                        z-index: 10; }
                    @media screen and (max-width: 320px) {
                        height: 100px; }
                    h4 {
                        font-family: Arial, sans-serif, futurademi;
                        font-style: normal;
                        font-weight: bold;
                        line-height: 18px;
                        color: #000000;
                        @media screen and (max-width: 480px) {
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            overflow: hidden; } }
                    a {
                        display: flex;
                        margin-top: 10px;
                        width: 100px;
                        @media screen and (max-width: 480px) {
                            width: 155px;
                            bottom: 0; }
                        .read-more {
                            font-family: Arial, futuralight;
                            font-style: normal;
                            font-weight: bold;
                            font-size: 10px;
                            color: #000000;
                            width: 145px;
                            text-transform: uppercase;
                            @media screen and (max-width: 480px) {
                                font-size: 16px;
                                width: 155px;
                                margin-bottom: 3px; } }
                        .read-more-arrow {
                            width: 6px;
                            height: 5px;
                            border-left: 2px solid #000000;
                            border-top: 2px solid #000000;
                            transform: rotate(135deg);
                            margin-top: 3px;
                            @media screen and (max-width: 480px) {
                                margin-top: 5px;
                                width: 6px;
                                height: 6px; } } } } } } }
    .slider-social {
        width: 405px;
        @media screen and (max-width: 930px) {
            height: 30%;
            width: 100%; }
        @media screen and (max-width: 480px) {
            height: 5%;
            margin-bottom: 30px; }
        h3 {
            text-align: center;
            margin-top: 35%;
            font-family: Arial, sans-serif, futurademi;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 20px;
            color: #000000;
            @media screen and (max-width: 930px) {
                margin-top: 20%; }
            @media screen and (max-width: 480px) {
                margin-top: 5%; } }
        .slider-social-contact {
            display: flex;
            justify-content: space-between;
            width: 205px;
            margin-left: auto;
            margin-right: auto;
            a {
                .slider-social-helper {
                    display: none; }
                .slider-social-inst {
                    margin-top: 13px;
                    width: 46px;
                    height: 46px;
                    background-image: url('../img/png/sprite.png');
                    background-position: -622px -26px; }
                &:hover {
                    .slider-social-helper.inst {
                        display: block;
                        position: absolute;
                        top: 33em;
                        @media screen and (max-width: 943px) {
                            top: 51em; }
                        width: 80px;
                        height: 20px;
                        text-align: center;
                        border: 1px solid #000000;
                        background: #F1DFF4;
                        border-radius: 5px;
                        color: #000000; } } }
            a {
                .slider-social-facebook {
                    margin-top: 13px;
                    width: 46px;
                    height: 46px;
                    background-image: url('../img/png/sprite.png');
                    background-position: -687px -26px; }
                &:hover {
                    .slider-social-helper.face {
                        display: block;
                        position: absolute;
                        top: 33em;
                        @media screen and (max-width: 943px) {
                            top: 51em; }
                        width: 80px;
                        height: 20px;
                        text-align: center;
                        border: 1px solid #000000;
                        background: #F1DFF4;
                        border-radius: 5px;
                        color: #000000; } } }
            a {
                .slider-social-mail {
                    margin-top: 13px;
                    width: 46px;
                    height: 46px;
                    background-image: url('../img/png/sprite.png');
                    background-position: -753px -26px; }
                &:hover {
                    .slider-social-helper.mail {
                        display: block;
                        position: absolute;
                        top: 33em;
                        @media screen and (max-width: 943px) {
                            top: 51em; }
                        width: 80px;
                        height: 20px;
                        text-align: center;
                        border: 1px solid black;
                        background: #F1DFF4;
                        border-radius: 5px;
                        color: #000000; } } } } } }
