* {
    box-sizing: border-box; }
body {
    margin: 0px; }
a {
    text-decoration: none; }
article {
    margin-left: auto;
    margin-right: auto;
    height: auto;
    h2 {
        text-align: center;
        margin-top: 30px;
        margin-bottom: 25px;
        font-family: Arial, sans-serif, futurademi;
        font-style: normal;
        font-weight: normal;
        font-size: 38px;
        color: #000000;
        @media screen and (max-width: 480px) {
            font-size: 32px;
            margin-top: 50px; } }

    @import 'adaptiv/button';
    @import 'imports/blog/slider-blog';

    .posts-middle-line {
                margin-top: 40px;
                border: 1px solid rgba(193, 187, 187, 0.2);
                box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.25);
                width: 100%;
                margin-left: auto;
                margin-right: auto;
                @media screen and (max-width: 480px) {
                    margin-top: 0px; } }

    #first-mid-line {
        width: 930px;
        @media screen and (max-width: 930px) {
            width: 100%; } }

    .blogs-content {
        width: 930px;
        margin-left: auto;
        margin-right: auto;
        @media screen and (max-width: 930px) {
            width: 100%; }
        @media screen and (max-width: 480px) {
            width: 100%;
            padding-left: 10px;
            padding-right: 10px; } }

    @import 'imports/blog/blog-1';

    @import 'imports/blog/blog-buttons'; }

