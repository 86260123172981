.blog {
    height: 320px;
    width: 100%;
    margin-top: 40px;
    display: flex;
    flex-wrap: wrap;
    @media screen and (max-width: 930px) {
        height: 640px;
        flex-direction: column-reverse;
        margin-left: auto;
        margin-right: auto; }
    @media screen and (max-width: 480px) {
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        position: relative;
        border-radius: 25px;
        height: auto;
        margin-bottom: 30px;
        &:hover {
            background: #F1DFF4; } }

    .blog-text {
        width: 450px;
        @media screen and (max-width: 930px) {
            height: 50%;
            margin-left: auto;
            margin-right: auto;
            padding-top: 50px;
            padding-left: 10px; }
        @media screen and (max-width: 480px) {
            width: 100%;
            height: auto;
            padding-top: 5px; }
        span {
            font-family: Arial, futuralight;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 20px;
            color: #000000;
            @media screen and (max-width: 480px) {
                display: none; } }

        .h3-href {
            display: block;
            width: 380px;
            height: 85px;
            @media screen and (max-width: 480px) {
                display: none;
                width: 100%; }
            a {
                width: 380px;
                @media screen and (max-width: 380px) {
                    width: 100%; }
                h3 {
                    margin-top: 5px;
                    font-family: Arial, sans-serif, futurademi;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 25px;
                    line-height: 32px;
                    width: 380px;
                    color: #000000;
                    display: block; } } }
        h3 {
            margin-top: 5px;
            font-family: Arial, sans-serif, futurademi;
            font-style: normal;
            font-weight: bold;
            font-size: 25px;
            line-height: 32px;
            width: 380px;
            color: #000000;
            display: none;
            @media screen and (max-width: 480px) {
                display: block;
                text-align: center;
                font-size: 18px;
                line-height: 24px;
                width: 100%;
                margin-top: 0px;
                margin-left: auto;
                margin-right: auto; } }

        p {
            font-family: Arial, futuralight;
            font-style: normal;
            font-weight: normal;
            font-size: 19px;
            width: 380px;
            overflow: hidden;
            line-height: 24px;
            color: #000000;
            @media screen and (max-width: 480px) {
                height: auto;
                line-height: 21px;
                margin-top: 5px;
                width: 100%;
                margin-left: auto;
                margin-right: auto;
                text-align: center;
                padding: 25px; }
            @media screen and (max-width: 320px) {
                padding: 15px; } }

        a {
            display: flex;
            margin-top: 18px;
            width: 150px;
            @media screen and (max-width: 480px) {
                // margin-left: auto;
                // margin-right: auto;
                width: 100%;
                height: 100%;
                top: 0px;
                position: absolute; }
            .read-more {
                font-family: Arial, futuralight;
                font-style: normal;
                font-weight: bold;
                font-size: 16px;
                color: #000000;
                width: 145px;
                @media screen and (max-width: 480px) {
                    position: absolute;
                    bottom: 5%;
                    left: 50%;
                    margin-left: -90px; } }
            .read-more-arrow {
                width: 6px;
                height: 6px;
                border-left: 2px solid #000000;
                border-top: 2px solid #000000;
                transform: rotate(135deg);
                margin-top: 5px;
                @media screen and (max-width: 480px) {
                    position: absolute;
                    bottom: 6%;
                    left: 50%;
                    margin-left: 60px; } } } }

    .blog-img {
        height: 320px;
        width: 480px;
        @media screen and (max-width: 930px) {
            height: 50%;
            margin-left: auto;
            margin-right: auto; }
        @media screen and (max-width: 480px) {
            width: 100%;
            top: 0; }
        img {
            display: block;
            margin-left: auto;
            margin-right: auto;
            width: auto;
            height: 100%;
            @media screen and (max-width: 480px) {
                width: 100%;
                border-radius: 25px 25px 0 0; } } } }
